<template>
    <div>
        <Header :showback="false" :customstyle="{ background: '#0D1912' }">
            <template slot="title">交易</template>
        </Header>
        <div class="content">
            <div class="card">
                <div style="display: flex;" v-if="inputtopup1=='ATT'">
                    <div class="ablesold">
                        <div class="num">{{ parseFloat(usdtBanner).toFixed(4) }}</div>
                        <div class="txt">可交易数量(USDT)</div>
                    </div>
                    <div class="ablesold">
                        <div class="num">{{ parseFloat(attBanner).toFixed(4) }}</div>
                        <div class="txt">可交易数量(ATT)</div>
                    </div>
                </div>
                <div style="display: flex;" v-if="inputtopup1=='ATTNEW'">
                    <div class="ablesold">
                        <div class="num">{{ parseFloat(usdtBanner).toFixed(4) }}</div>
                        <div class="txt">可交易数量(USDT)</div>
                    </div>
                    <div class="ablesold">
                        <div class="num">{{ parseFloat(walletList['ATT']).toFixed(4) }}</div>
                        <div class="txt">余额(ATT)</div>
                    </div>
                </div>
                <div class="exinput">
                    <div class="inputcontent">
                        <van-field
                                readonly
                                class="inputClass"
                                v-model="inputtopup"
                                placeholder=""

                        />
                    </div>
                    <!--@click="changeType"-->
                    <img
                            class="arrow"
                            :src="require('@/assets/images/mine/darrow.png')"
                    />
                    <div class="inputcontent"  @click="clickInput">
                        <van-field
                                class="inputClass"
                                readonly
                                v-model="inputtopupText"
                        />
                        <!--:placeholder="attNum+' ATT'"-->
                    </div>
                </div>
                <div class="inputcontent">
                    <van-field class="inputClass" v-model="inputtoNum" @keyup="checkNum"  placeholder="请输入USDT购买数量（按USDT金额购买）" />
                </div>
                <div class="inputcontent" v-if="inputtopup1=='ATTNEW'" style="color:white;display: flex; justify-content: space-between;">
                    <div>
                        单价:1ATT ≈ {{config.att_trade_price}}USDT
                    </div>
                    <div>
                        预期获得:{{ attNum }} ATT
                    </div>
                </div>
                <div class="inputcontent" v-if="inputtopup1=='ATTNEW'" style="color:#27cc7f;display: flex; justify-content: space-between;">
                    <div>
                        已保底 {{ parseFloat(userInfo.att_buy_num).toFixed(2) }} USDT
                    </div>
                    <div> </div>
                </div>
                <div class="paybtn" v-if="inputtopup1=='ATT'">
                    <div class="btn" @click="toSubmitDui($event)" v-if="type==1">买入</div>
                    <div class="btn" @click="toSubmitDui($event)" v-else>卖出</div>
                </div>
                <div class="paybtn" v-if="inputtopup1=='ATTNEW'">
                    <div class="btn" @click="duiConfirm($event)">买入</div>
                </div>
            </div>
        </div>
        <van-picker class="picker" v-if="showPicker" show-toolbar title="选择类型" :columns="columns" :default-index="0"
                    @cancel="onCancel" @confirm="onConfirm"/>
        <van-dialog v-model="showpwd" title="支付" @cancel="cancel"  @confirm="confirm" show-cancel-button>
            <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
        </van-dialog>
    </div>
</template>

<script>
    import Header from "../../components/header.vue";
    import { ethers } from '../../components/ethers-5.1.esm.min.js'
    import metamaskConfig from '../../components/connection.js'
    import { Toast } from 'vant';
    import $ from 'jquery'
    import { getWalletInfo, duiConfig,canDui} from '@/request/api'
    export default {
        components: {
            Header,
        },
        data() {
            return {
                showPicker: false,
                columns: [
                    'ATT薄饼',
                    'ATT保本'
                ],
                address: '',
                userInfo: {},
                attBanner: 0,
                showpwd: false,
                inputtopup:'USDT',
                inputtopup1:'ATTNEW',
                inputtopupText:'ATT保本',
                inputtoNum:'',
                type:1, // 1买  2卖
                usdtBanner:0,
                password: '',
                walletList:{
                    'ARR':0,
                    'ATT':0,
                    'EPT':0,
                    'USDT':0,
                },
                config: {
                    ept_price: "0.15",
                    arr_price: "2000",
                    et_price: "1",
                    att_trade_price:0,
                    dui_et_min: "100"
                },
                attNum:0,
            };
        },
        activated() {
            this.address = sessionStorage.getItem("address");
            this.userInfo = sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):{};
            this.getduiConfig()
            if(this.address){
                this.getBanner();
                this.getWalletInfoData()
            }
        },
        methods: {
            getduiConfig() {
                duiConfig().then(res => {
                    this.config = res.data
                })
            },
            async duiConfirm(){
                // Toast("暂未开放!");
                // return false;
                // this.showpwd = true;
                // this.password = '';
                var _this = this;
                var toggle = event.currentTarget;
                if(this.inputtopup<=0){
                    Toast('请输入交易数量')
                    return false;
                }
                if(!this.address){
                    Toast('请使用币安智能链链接')
                    return false;
                }
                const gasPrice = await metamaskConfig.provider.getGasPrice();
                let etherNum = ethers.utils.parseEther (_this.inputtoNum.toString());
                if(parseFloat(this.inputtoNum)>parseFloat(this.usdtBanner)){
                    Toast('USDT余额不足');
                    return false;
                }
                if(this.userInfo.partner_level_id==1||this.userInfo.partner_level_id==4){
                    Toast("请使用普通账号交易");
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.usdtContract.allowance(this.address,contractConfig.att2ConAddress).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.usdtContract.approve(contractConfig.att2ConAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.att2ConContract.BuyATT(etherNum,{
                        gasPrice: gasPrice,
                        gasLimit:500000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                }).catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    return false;
                });
            },
            cancel() {
                this.showpwd = false;
                this.password = '';
            },
            confirm () {
                let _this = this;
                if(!this.address){
                    Toast('请使用币安智能链链接')
                    return
                }
                if(this.address&&this.userInfo.has_paypwd!=1){
                    Toast('请先完善资料,设置安全密码');
                    setTimeout(function () {
                        _this.$router.push({
                            path:'/home/mine'
                        })
                    })
                    return
                }
                if(this.password.length === 0){
                    Toast('请输入安全密码')
                    return;
                }
                if(this.inputtoNum<=0){
                    Toast('请输入数量')
                    return;
                }
                if(this.userInfo.partner_level_id==1||this.userInfo.partner_level_id==4){
                    Toast("请使用普通账号交易");
                    return false;
                }
                if(parseFloat(this.inputtoNum)>parseFloat(this.walletList['USDT'])){
                    Toast('可用余额不足');
                    return;
                }
                const params = {
                    address:this.address,
                    pay_code:this.password,
                    amount:this.inputtoNum,
                    coin_symbol:'USDT',
                }
                canDui(params).then(res=> {
                    _this.password = '';
                    if(res.code==200){
                        Toast('买入成功')
                        setTimeout(function () {
                            location.reload();
                        },3000)
                    }else{
                        Toast(res.msg)
                    }
                }).catch(err=> {
                    Toast('买入失败')
                })
            },
            getWalletInfoData() {
                let _this = this;
                const params = {
                    address: this.address,
                }
                getWalletInfo(params).then((res) => {
                    $.each(res.data.list,function (i,ele) {
                        _this.walletList[ele.coin_symbol] = parseFloat(ele.cloud_balance).toFixed(4);
                    })
                    console.log(_this.walletList)
                })
            },
            onCancel() {
                this.showPicker = false
            },
            onConfirm(value) {
                this.showPicker = false
                this.inputtopupText = value
                if(this.inputtopupText=='ATT保本'){
                    this.inputtopup1 = 'ATTNEW'
                }else{
                    this.inputtopup1 = 'ATT'
                }

            },
            clickInput() {
                this.showPicker = true
            },
            changeType(){
                if(this.type==1){
                    this.type=2;
                    this.inputtopup='ATT';
                    this.inputtopup1='USDT';
                }else{
                    this.type=1;
                    this.inputtopup1='ATT';
                    this.inputtopup='USDT';
                }
            },
            async getBanner(){
                let _this = this;
                await metamaskConfig.usdtContract.balanceOf(this.address).then( re => {
                    _this.usdtBanner = ethers.utils.formatEther(re)
                }).catch(err => {
                    console.log(err);
                    return;
                });
                await metamaskConfig.attNewContract.balanceOf(this.address).then( re => {
                    _this.attBanner = ethers.utils.formatEther(re)
                }).catch(err => {
                    console.log(err);
                    return;
                });
            },
            checkNum(){
                if(parseFloat(this.inputtoNum)<0){
                    this.inputtoNum=0;
                }
                this.attNum = parseFloat(this.inputtoNum/this.config.att_trade_price).toFixed(4);
            },
            async toSubmitDui(event) {
                var _this = this;
                var toggle = event.currentTarget;
                if(this.inputtopup<=0){
                    Toast('请输入交易数量')
                    return false;
                }
                if(!this.address){
                    Toast('请使用币安智能链链接')
                    return false;
                }
                const gasPrice = await metamaskConfig.provider.getGasPrice();
                let etherNum = ethers.utils.parseEther (_this.inputtoNum.toString());
                if(this.type==1){ //买入
                    if(parseFloat(this.inputtoNum)>parseFloat(this.usdtBanner)){
                        Toast('USDT余额不足');
                        return false;
                    }
                    if(_this.tokenDisable){
                        return false;
                    }
                    _this.tokenDisable = true;
                    $(toggle).css('background','#dddddd');
                    await metamaskConfig.usdtContract.allowance(this.address,contractConfig.tradeCon).then(async res =>{
                        if(parseFloat(ethers.utils.formatEther(res))<=0){
                            await metamaskConfig.usdtContract.approve(contractConfig.tradeCon,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                                gasPrice: gasPrice,
                                gasLimit:250000,
                            });
                        }
                        let tx = await metamaskConfig.tradeContract.buy(etherNum,{
                            gasPrice: gasPrice,
                            gasLimit:1500000,
                        })
                        if(tx){
                            Toast('操作成功');
                            _this.tokenDisable = false;
                            setTimeout(function () {
                                location.reload();
                            },3000)

                        }
                    }).catch(err => {
                        Toast('操作失败')
                        _this.tokenDisable = false;
                        return false;
                    });
                }
                else{  //卖出
                    if(parseFloat(this.inputtoNum)>parseFloat(this.attBanner)){
                        Toast('ATT余额不足');
                        return false;
                    }
                    if(_this.tokenDisable){
                        return false;
                    }
                    _this.tokenDisable = true;
                    $(toggle).css('background','#dddddd');
                    await metamaskConfig.attNewContract.allowance(this.address,contractConfig.tradeCon).then(async res =>{
                        if(parseFloat(ethers.utils.formatEther(res))<=0){
                            await metamaskConfig.attNewContract.approve(contractConfig.tradeCon,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                                gasPrice: gasPrice,
                                gasLimit:250000,
                            });
                        }
                        let tx = await metamaskConfig.tradeContract.sell(etherNum,{
                            gasPrice: gasPrice,
                            gasLimit:1500000,
                        })
                        if(tx){
                            Toast('操作成功');
                            _this.tokenDisable = false;
                            setTimeout(function () {
                                location.reload();
                            },3000)

                        }
                    }).catch(err => {
                        Toast('操作失败')
                        _this.tokenDisable = false;
                        return false;
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .picker {
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .content {
        padding: 10px 32px;
        background: #0c1913;
        min-height: 100vh;
        .card {
            padding: 22px 24px;
            width: 686px;
            height: 658px;
            border-radius: 24px;
            opacity: 1;
            background: rgba(32, 40, 35, 1);
            .ablesold {
                width: 638px;
                height: 168px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(39, 204, 127, 0.2);
                background: rgba(16, 43, 29, 1);
                display: flex;
                flex-direction: column;
                justify-content: center;
                .num {
                    color: rgba(214, 255, 227, 1);
                    font-size: 44px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                }
                .txt {
                    color: rgba(182, 217, 195, 1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 36px;
                }
            }
            .inputcontent {
                margin-top: 16px;
                display: flex;
                justify-content: center;
                .inputClass {
                    width: 632px;
                    height: 96px;
                    background: #29332D;
                    border-radius: 16px;
                    ::v-deep .van-field__control{
                        color: white;
                    }
                }
            }
            .exinput {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 16px;
                .inputcontent {
                    width: 276px;
                    border-radius: 16px;
                    opacity: 1;
                    background: rgba(41, 51, 45, 1);
                    .inputClass {
                        width: 276px;
                        background: #29332d;
                        border-radius: 16px;
                        ::v-deep .van-field__control {
                            color: white;
                        }
                    }
                }
                .arrow {
                    width: 36px;
                    height: 36px;
                }
            }
            .paybtn {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                .btn {
                    width: 320px;
                    height: 88px;
                    border-radius: 16px;
                    opacity: 1;
                    background: rgba(39, 204, 127, 1);
                    color: rgba(32, 40, 35, 1);
                    font-size: 36px;
                    font-weight: 700;
                    font-family: "PingFang SC";
                    text-align: center;
                    line-height: 88px;
                }
            }
            .poundage {
                margin-top: 16px;
                color: rgba(139, 166, 149, 1);
                font-size: 28px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: center;
            }
        }
        .middlecontent {
            margin-top: 24px;
            margin-bottom: 48px;
            color: rgba(182, 217, 195, 1);
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            font-family: "PingFang SC";
            line-height: 45px;
        }
        .record {
            border-radius: 24px;
            opacity: 1;
            background: rgba(32, 40, 35, 1);
            padding: 24px;
            .title {
                opacity: 1;
                color: rgba(214, 255, 227, 1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
            }
            .datalist {
                .date {
                    margin-top: 32px;
                    color: rgba(139, 166, 149, 1);
                    font-weight: 400;
                    text-align: left;
                    line-height: 52px;
                    font-size: 40px;
                }
                .detail {
                    display: flex;
                    justify-content: space-between;
                    .topnum {
                        margin-top: 32px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        .num {
                            color: rgba(214, 255, 227, 1);
                            font-size: 44px;
                            font-weight: 500;
                            font-family: "DIN";
                        }
                        .txt {
                            color: rgba(139, 166, 149, 1);
                            font-size: 24px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: center;
                            line-height: 36px;
                        }
                        .time {
                            color: rgba(139, 166, 149, 1);
                            font-size: 28px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                        }
                    }
                    .status {
                        display: flex;
                        align-items: center;
                        color: rgba(39, 204, 127, 1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                    }
                }
            }
        }
    }
</style>